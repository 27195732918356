import { Component, OnInit } from '@angular/core';
import { GluePlanService, ILamellaNeedReportDTO } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { SVGIcon, printIcon } from '@progress/kendo-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { GlusetStateHelper } from '@app-module-gluelam/glue-plans-history/gluplan-state-helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductionModelService } from '@app/core/services/http-services/gluelam/production-model.service';
import { gluplanColorMap } from '@app-module-gluelam/glue-plans-history/glue-plans-history.component';

@Component({
  selector: 'app-glueplan-print-first',
  templateUrl: './glueplan-print-first.component.html',
  styleUrls: ['./glueplan-print-first.component.css']
})
export class GlueplanPrintFirstComponent implements OnInit {
  gluePlan: GluingPlan;
  glusets: BeamMaterialGlueSet[];
  qualitys: string = 'N/A';
  lamellas: { [gluesetId: string]: string; } = {};
  lamNeed: ILamellaNeedReportDTO;
  getLaminasFilterIndexs: number[] = [];
  isCompletedGlueplan: boolean = false;
  isCompletedLamNeed: boolean = false;
  isCompletedGlueSetByGluePlan: boolean = false;

  printIcon: SVGIcon = printIcon;

  constructor(private route: ActivatedRoute,
    private readonly gpservice: GluePlanService,
    private readonly gsservice: GlueSetService,
    private readonly productionModelService: ProductionModelService,
    private spinner: NgxSpinnerService) {
    this.route.params.subscribe({
      next: params => {
        this.getGluingPlan(params.id);
        this.getLamNeed(params.id);
        this.getGlueSetByGluingPlanId(params.id);
      }
    });
  }

  ngOnInit(): void {
    this.showLoader();
  }

  getStateColor(code: number) {
    return gluplanColorMap(code);
  }

  getStateText(code: number): string {
    return GlusetStateHelper.getStateText(code);
  }

  firstBm(bmgs: BeamMaterialGlueSet): BeamMaterial {
    const layer = bmgs.layers[0];
    return layer.BeamMaterials[0];
  }

  printComponent(cmpName) {
    const printContents = document.getElementById(cmpName).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();
  }

  private getGluingPlan(gpId: number) {
    this.gpservice.getGluingPlan(gpId).subscribe({
      next: gp => {
        this.gluePlan = gp;
        this.onGetGluingPlanResponse();
      },
      error: (err) => {
        console.log(err);
        this.onGetGluingPlanResponse();
      }
    });
  }

  private getLamNeed(gpId: number) {
    this.gpservice.getLamNeed(gpId).subscribe({
      next: l => {
        this.lamNeed = l;
        this.onGetLamNeedResponse();
      },
      error: (err) => {
        console.log(err);
        this.onGetLamNeedResponse();
      }
    });
  }

  private getGlueSetByGluingPlanId(gpId: number) {
    this.gsservice.getGlueSetByGluingPlanId(gpId).subscribe({
      next: gps => {
        if (gps.length > 0) {
          this.glusets = gps;
          this.loadGlusets(gps).then(() => {
            const materialIndexes = this.getGlusetLayerBeamMaterial();
            this.getLamillasQuality(materialIndexes).then(() => {
              this.onGetGlueSetByGluingPlanIdResponse();
            });
          });
        } else {
          this.onGetGlueSetByGluingPlanIdResponse();
        }
      },
      error: (err) => {
        console.log(err);
        this.onGetGlueSetByGluingPlanIdResponse();
      }
    });
  }

  private loadGlusets(gps: BeamMaterialGlueSet[]): Promise<boolean> {
    return new Promise((resolve) => {
      this.glusets.forEach((gs, index, array) => {
        this.getLaminnas(gs, index).then(() => {
          if (this.getLaminasFilterIndexs.length === array.length) {
            resolve(true);
          }
        });
      });
    });
  }

  private getLaminnas(gs: BeamMaterialGlueSet, index: number): Promise<boolean> {
    return new Promise((resolve) => {
      const materialIds: number[] = [];
      if (gs.layers.length > 0) {
        gs.layers.forEach((l) => {
          l.BeamMaterials.forEach((bm) => {
            materialIds.push(bm.materialIndex);
          });
        });
        this.productionModelService.getProductionModelsForEntityIds(materialIds).subscribe({
          next: (pms) => {
            if (pms) {
              const result = pms.map(({ materialCode }) => materialCode.substring(4, 7)).filter((value, index, self) => self.indexOf(value) === index).filter(x => x.indexOf('L25') < 0).join(', ');
              if (!this.getLaminasFilterIndexs.includes(index)) {
                this.getLaminasFilterIndexs.push(index);
              }
              this.lamellas[gs.beamMaterialGlueSetID.toString()] = result;
              resolve(true);
            }
          },
          error: (err) => {
            console.log(err);
            resolve(false);
          }
        });
      }
    });
  }

  private getGlusetLayerBeamMaterial(): number[] {
    const materialIndexes: number[] = [];
    this.glusets.forEach((gs) => {
      gs.layers.forEach(l => {
        l.BeamMaterials.forEach(bm => {
          if (bm.materialIndex && materialIndexes.indexOf(bm.materialIndex) === -1) {
            materialIndexes.push(bm.materialIndex);
          }
          gs.isMainProductWE = this.hasMainProductWE(bm);
        });
      });
    });
    return materialIndexes;
  }

  private getLamillasQuality(materialIndexes: number[]): Promise<boolean> {
    return new Promise((resolve) => {
      this.gsservice.getLamillaQuality(materialIndexes).subscribe({
        next: result => {
          if (result.length > 0) {
            const qualitys: string[] = [];
            result.forEach(r => {
              if (r === 'S' && qualitys.indexOf('Sicht') === -1) {
                qualitys.push('Sicht');
              } else if (r === 'I' && qualitys.indexOf('Industrie') === -1) {
                qualitys.push('Industrie');
              }
            });
            this.qualitys = qualitys.join(', ');
          }
          resolve(true);
        },
        error: (err) => {
          console.log(err);
          resolve(false);
        }
      });
    });
  }

  private hasMainProductWE(bm: BeamMaterial) {
    return bm.beamMaterialLines.some(line => line.transportPackageDemand.mainProduct === "WE")
  }

  private hideLoader() {
    this.spinner.hide('glueplanLoader');
  }

  private showLoader() {
    this.spinner.show('glueplanLoader');
  }

  // ---------------- Response Method -- Start

  private onGetGluingPlanResponse() {
    this.isCompletedGlueplan = true;
    this.onResponse();
  }

  private onGetLamNeedResponse() {
    this.isCompletedLamNeed = true;
    this.onResponse();
  }

  private onGetGlueSetByGluingPlanIdResponse() {
    this.isCompletedGlueSetByGluePlan = true;
    this.onResponse();
  }

  private onResponse() {
    if (this.isCompletedGlueplan && this.isCompletedLamNeed && this.isCompletedGlueSetByGluePlan) {
      this.hideLoader();
    }
  }

  // ----------------  Response Method -- End
}
