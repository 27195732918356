
<div class="col" style="border-radius:2px; padding:5px;">
  <div style="padding:5px;" *ngIf="bml">
    {{ bml.getLongText() }}
  </div>
  <div *ngIf="bml == null && bm != null">
    <div
      style="padding:5px;"
      *ngFor="let bml of bm.beamMaterialLines; let i = index">
      #{{ i }} - {{ bml.getLongText() }}
    </div>
  </div>

  <div class="row" style="gap: 5px">
    <div style="width: 45px;">BM</div>

    <button
      *ngIf="isStaticalGluePlan"
      kendoButton size="large"
      themeColor="primary"
      [svgIcon]="plusIcon"
      (click)="onManualBeamMaterialAdd()"
      [disabled]="!canManualBeamMaterialAdd()">
    </button>

    <button
      kendoButton
      size="large"
      themeColor="primary"
      [svgIcon]="arrowUpIcon"
      (click)="onUpp()"
      [disabled]="!bm || showTestPscSection">
    </button>

    <button
      kendoButton
      size="large"
      themeColor="primary"
      [svgIcon]="arrowDownIcon"
      (click)="onDown()"
      [disabled]="!bm || showTestPscSection">
    </button>

    <button
      kendoButton
      size="large"
      themeColor="primary"
      [svgIcon]="cutIcon"
      (click)="onCutBM()"
      [disabled]="!bm || showTestPscSection">
    </button>

    <button
      kendoButton
      size="large"
      themeColor="primary"
      (click)="showTestPscForm()"
      [disabled]="showTestPscSection"
      *ngIf="bm && !bm.beamMaterialType.isManual && !bm.takeTestPsc">
      + tp
    </button>

    <button
      kendoButton
      size="large"
      themeColor="primary"
      (click)="onRemoveTestPsc()"
      *ngIf="bm && bm.takeTestPsc">
      - tp
    </button>

    <button
      kendoButton
      size="large"
      themeColor="primary"
      (click)="openZulagenDialog()"
      *ngIf="bm && bm.beamMaterialType.isSpecialForm && !hasZulagen()">
    + zl
    </button>

   <button
    kendoButton
    size="large"
    themeColor="primary"
    (click)="onRemoveZulagen()"
    *ngIf="bm && bm.beamMaterialType.isSpecialForm && hasZulagen()">
   - zl
   </button>

    <button
      kendoButton
      size="large"
      themeColor="error"
      [svgIcon]="minusIcon"
      (click)="onRemoveMaterial()"
      [disabled]="!bm || showTestPscSection">
    </button>

  </div>
  <div class="row" style="gap: 5px">
    <div style="width: 45px;">BML</div>
    <button
      title="alt a"
      accesskey="a"
      [svgIcon]="plusIcon"
      kendoButton size="large"
      themeColor="primary"
      (click)="onAdd()"
      [disabled]="!canAdd() || showTestPscSection">
    </button>

    <button
      kendoButton size="large"
      themeColor="primary"
      [svgIcon]="cutIcon"
      (click)="onCut()"
      [disabled]="!bml || bm?.beamMaterialType.isManual || showTestPscSection">
    </button>

    <button
      kendoButton size="large"
      themeColor="primary"
      [svgIcon]="clipboardIcon"
      (click)="onPaste()"
      [disabled]="!hasPaste || showTestPscSection">
    </button>

    <button title="alt r"  accesskey="r"
      kendoButton size="large"
      themeColor="error"
      [svgIcon]="minusIcon"
      (click)="onRemoveMaterialLine()"
      [disabled]="!bml || showTestPscSection">
    </button>

  </div>

  <form *ngIf="showTestPscSection" class="row" [formGroup]="testPieceForm" (ngSubmit)="onAddTestPsc()" style="gap: 5px;" >
    <kendo-textbox type="text" formControlName="testPscValue" [ngModel]="testPieceForm.get('testPscValue').value | ReplaceDotToCommaPipe"/>
    <button kendoButton themeColor="primary" size="large" type="submit"  [disabled]="testPieceForm?.invalid">
      {{"App.Save" | translate }}
    </button>
    <button kendoButton themeColor="warning" size="large" (click)="onCancel()" type="cancel">
      {{ 'App.Cancel' | translate}}
    </button>
  </form>

  </div>
