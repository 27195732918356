<h1 translate>{{def?.titel}}</h1>

<form
    [formGroup]="form"
    (ngSubmit)="load()"
    class="row"
    style="margin-bottom: 10px;"
>
    <kendo-formfield appearance="fill">
        <kendo-label translate>App.StartDate</kendo-label>
        <kendo-datepicker formControlName="start"></kendo-datepicker>
    </kendo-formfield>
    <kendo-formfield appearance="fill">
        <kendo-label translate>App.EndDate</kendo-label>
        <kendo-datepicker formControlName="end"></kendo-datepicker>
    </kendo-formfield>
    <div style="display: flex; align-items: flex-end;">
      <button size="large" kendoButton themeColor="primary">Search</button>
    </div>
</form>
<kendo-tilelayout
    [columns]="settings.numberOfCols"
    [rowHeight]="settings.rowhight"
>
    <kendo-tilelayout-item
        *ngFor="let card of def?.cards"
        [col]="card?.col"
        [row]="card?.row"
        [colSpan]="card?.cols"
        [rowSpan]="card?.rows"
        title="{{ card.title | translate }}"
    >
        <kendo-tilelayout-item-body>
            <app-dashboard-layout-card
                [card]="card"
                [update]="update"
            ></app-dashboard-layout-card>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>
