import { Injectable, SimpleChange } from "@angular/core";
import { Demandline } from "@app/core/models/demand-line.model";

@Injectable()
export class OrderViewService {
  changes:SimpleChange;

  constructor() {}

  public loadDataWithNewUiQty(demands: Demandline[], change: SimpleChange, selectedPlan: any): Demandline[] {
    if (change === null) return demands;
    this.changes = change;
    const maxChangeCount = this.getMaxCount(change);

    maxChangeCount.forEach((m) => {
      const isOptimizerGPSelected = selectedPlan?.gluingPlanID === 0;
      const d = demands.find((k) => k.BeamOrderRowID === m.demandId && k.transportPackageId === m.transportPackageId);

      if (!d) return;

      let gluingPlandIds = this.getGluingPlanIds(d.gluingPlandIds);

      if (this.isSelectedPlan(m, isOptimizerGPSelected, selectedPlan)) {
        const { current, previous } = this.getCurrentAndPrevious(change, m, isOptimizerGPSelected, selectedPlan);

        if (current) {
          this.handleCurrentAndPrevious(d, current, previous, gluingPlandIds, isOptimizerGPSelected, selectedPlan);
        } else if (previous) {
          this.handleOnlyPrevious(d, previous, gluingPlandIds, isOptimizerGPSelected, selectedPlan);
        }
      }
    });

    return demands;
  }

  public getMaxCount(change: SimpleChange = null): any {
    return change.currentValue.length >= change.previousValue.length ? change.currentValue : change.previousValue;
  }

  private getGluingPlanIds(gluingPlandIds: string | null | undefined): number[] {
    if (!gluingPlandIds || gluingPlandIds.trim() === "") return [];
    return gluingPlandIds
      .split(",")
      .map(id => parseInt(id, 10))
      .filter(id => !isNaN(id));
  }

  private isSelectedPlan(m: any, isOptimizerGPSelected: boolean, selectedPlan: any): boolean {
    return isOptimizerGPSelected
      ? m.gluePlanIndex === selectedPlan?.gluePlanIndex
      : m.gluePlanId === selectedPlan?.gluingPlanID;
  }

  private getCurrentAndPrevious(change: SimpleChange, m: any, isOptimizerGPSelected: boolean, selectedPlan: any) {
    const current = change.currentValue.find(
      (k) => k.demandId === m.demandId && k.transportPackageId === m.transportPackageId &&
        (isOptimizerGPSelected ? k.gluePlanIndex === selectedPlan?.gluePlanIndex : k.gluePlanId === selectedPlan?.gluingPlanID)
    );

    const previous = change.previousValue.find(
      (k) => k.demandId === m.demandId && k.transportPackageId === m.transportPackageId &&
        (isOptimizerGPSelected ? k.gluePlanIndex === selectedPlan?.gluePlanIndex : k.gluePlanId === selectedPlan?.gluingPlanID)
    );

    return { current, previous };
  }

  private handleCurrentAndPrevious(d: Demandline, current: any, previous: any, gluingPlandIds: number[], isOptimizerGPSelected: boolean, selectedPlan: any): void {
    if (previous && previous.newQty !== current.newQty) {
      this.updateConsumedBML(d, current, previous, gluingPlandIds, isOptimizerGPSelected, selectedPlan);
    } else if (!previous && (d.gluingPlandIds === null || (isOptimizerGPSelected ? !gluingPlandIds.includes(current.gluePlanIndex) : !gluingPlandIds.includes(current.gluePlanId)))) {
      d.consumedBML += current.newQty;
      this.updateGluingPlandIds(d, gluingPlandIds, isOptimizerGPSelected, selectedPlan, current);
    }
  }

  private handleOnlyPrevious(d: Demandline, previous: any, gluingPlandIds: number[], isOptimizerGPSelected: boolean, selectedPlan: any): void {
    d.consumedBML -= previous.newQty;
    this.updateGluingPlandIds(d, gluingPlandIds, isOptimizerGPSelected, selectedPlan, previous);
  }

  private updateConsumedBML(d: Demandline, current: any, previous: any, gluingPlandIds: number[], isOptimizerGPSelected: boolean, selectedPlan: any): void {
    const totalConsumedPreviousQty = this.changes.previousValue
    .filter(k => k.demandId === previous.demandId && k.transportPackageId === previous.transportPackageId) // Filter objects based on conditions
    .reduce((sum, obj) => sum + obj.newQty, 0);

    const totalConsumedCurrentQty = this.changes.currentValue
    .filter(k => k.demandId === current.demandId && k.transportPackageId === current.transportPackageId) // Filter objects based on conditions
    .reduce((sum, obj) => sum + obj.newQty, 0);

    d.consumedBML = (d.consumedBML - totalConsumedPreviousQty) + totalConsumedCurrentQty;
    this.updateGluingPlandIds(d, gluingPlandIds, isOptimizerGPSelected, selectedPlan, current);
  }

  private updateGluingPlandIds(d: Demandline, gluingPlandIds: number[], isOptimizerGPSelected: boolean, selectedPlan: any, current: any): void {
    if (isOptimizerGPSelected && !gluingPlandIds.includes(selectedPlan?.gluePlanIndex)) {
      d.gluingPlandIds = d.gluingPlandIds
        ? `${d.gluingPlandIds},${selectedPlan?.gluePlanIndex}`
        : `${selectedPlan?.gluePlanIndex}`;
    }
  }
}
