import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IDashTileData, IDashTileMember } from '../models/CardItem.model';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { GltKpiService } from '@app/core/services/http-services/analytics/glt-kpi.service';
import th from '@mobiscroll/angular/dist/js/i18n/th';

@Component({
  selector: 'app-glt-kpi',
  template: `
    <h2>{{value | number}} {{uom}}</h2>`,
  styleUrls: ['./glt-kpi.component.scss']
})

export class GLTkpiComponent implements IDashTileMember, OnDestroy {
  showTitle: boolean = false;
  value:number = 0;

  uom:string = "PSC";

  private update: Subscription;
  private loading: Subject<boolean> = new Subject<boolean>();
  private hasError: Subject<boolean> = new Subject<boolean>();

  constructor(private gltKpiService:GltKpiService ) {}

  public data: any;

  subscribeUpdate(update: Subject<IDashTileData>) {
    this.update = update.subscribe(d => {
      this.onupdate(d)
    })
  }

  loadingCallback(): Subject<boolean> {
    return this.loading;
  }

  errorCallback(): Subject<boolean> {
    return this.hasError;
  }

  onupdate(data:IDashTileData) {
    this.loading.next(true);

    if(this.data?.kpiId == 'numberOfPlans'){


      this.gltKpiService.getNummberOfPlans(data).subscribe(r => {

        this.value = r.value;


        this.loading.next(false);
      });
    }else{
      this.gltKpiService.getNummberOfBeams(data).subscribe(r => {

        this.value = r.value;


        this.loading.next(false);
      });
    }


    this.value = 0;
    this.uom = "PSC";



  }
  ngOnDestroy(): void {
    this.update.unsubscribe();
  }
}
