import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  HostListener,
  OnInit,
  SimpleChange
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { process, State, DataResult, FilterDescriptor } from '@progress/kendo-data-query';
import {
  SelectAllCheckboxState,
  PageSizeItem,
  SelectionEvent,
  GridComponent,
  ColumnComponent,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {
  EntityPropertyService,
  IEntityProperty,
  IEntityPropertyValueValid
} from '@app/core/services/http-services/model/entity-property.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { EntityFilter } from '@app/core/services/http-services/model/entity.service';
import { firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Demandline } from '@app/core/models/demand-line.model';
import { DeamndService, UpdateSumUIQtyDTO, IDemandPartialDTO, IAltModelResponceDTO, IDemandDTO } from '@app-core-httpservice/gluelam/demand.service';
import { IOrderViewSetting } from '@app/core/models/order-view.model';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { DemandSpecificationService } from '@app/core/services/http-services/aot/demand-specification.service';
import { IPersistedColState, IPersistedGridState } from '@app/core/models/persist-grid-state';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { CreateOrderViewForm } from '@app/core/models/forms/orders/create-order-view-form.model';
import {
  SVGIcon,
  trashIcon,
  arrowLeftIcon,
  arrowRightIcon,
  warningTriangleIcon
} from "@progress/kendo-svg-icons";
import { IBeamMaterialType } from '@app/core/services/http-services/gluelam/glue-set.service';
import { BeamMaterialTypeService } from '@app/core/services/http-services/gluelam/beam-material-type.service';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { Guid } from 'guid-typescript';
import { parseDate } from '@progress/kendo-angular-intl';
import { OrderViewService } from '@app/modules/gluelam/services/order-view.service';

marker([
  'App.RemoveByRow',
  'App.RemoveByOrder',
  'App.RemoveByPackage',
  'OrderViewTranslation.BEAM_MAIN_PRODUCT',
  'OrderViewTranslation.BEAM_QUALITY',
  'OrderViewTranslation.BEAM_PROFILE',
  'OrderViewTranslation.BEAM_PLANED_PROD_DATE',
  'OrderViewTranslation.Show_all',
  'Operative.DemandRecordDeleted',
  'Operative.DemandDeletedSuccessfully',
  'Operative.PackageDeletedSuccessfully',
  'NORMAL',
  'SPECIAL_FORM',
  'ANGELD_CUT',
  'WIDTH_SPLIT',
  'ANGLED_HEIGHT_SPLIT',
  'ANGELD_HEIGHT_SLICE',
  'KVH',
  'UNKNOWN',
]);


const createFormGroup = (dataItem: Demandline) =>
  new FormGroup<CreateOrderViewForm>({
    DeamndId: new FormControl<number>(dataItem.BeamOrderRowID),
    LamminaPlaneThickness: new FormControl<number>(dataItem.DefaultLamminaPlaneThickness, Validators.required),
    LamminaPlaneWidth: new FormControl<number>(dataItem.LamminaPlaneWidth, Validators.required),
    beamLamminaDimensionId: new FormControl<number>({ value: dataItem.BeamLamminaDimensionID, disabled: true }, Validators.required),
    materialIndex: new FormControl<number>(dataItem.materialIndex, Validators.required),
    materialDescription: new FormControl<string>(dataItem.materialDescription, Validators.required),
    qtyReproduce: new FormControl<number>(dataItem.transportPackageAllocation?.unitsExtra),
    transportPackageId: new FormControl<number>(dataItem.transportPackageAllocation?.transportPackageID),
    rawLamellaThickness: new FormControl<number>(dataItem.rawLamellaThickness),
    siteId: new FormControl<Guid>(dataItem.siteId)
  });

const PLANE_LAMINA_THICKNESS = 'PLANE_LAMINA_THICKNESS';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styles: [`.grid-wrapper {
    position: relative;
  }
      .k-button {
        padding: 1px 4px;
      }
    `
  ]
})
export class OrderView implements OnChanges, OnDestroy, OnInit {

  @Input() public uiQty: UpdateSumUIQtyDTO[] = [];
  @Input() selectedLamminaDimension: BeamLamminaDimension;
  @Input() isLeftMajor: boolean;
  @Input() filterOnSelectGluePlan: boolean = true;
  @Input() selectedPlan: GluingPlan;
  @Input() siteId: string;
  @Input() isRefreshGrid: Observable<boolean>;

  @Output() optimise = new EventEmitter();
  @Output() updateSize = new EventEmitter<boolean>();

  initialLoad = true;
  selectRowCount = 0;
  pageSize = 200;
  saveInProgress = false;
  take = 200;
  showPlanned = false;
  showOnlyWithNoGluPlan = true;
  removeDemandInProgress = false;
  removeDemandByRowInProgress = false;
  removeDemandByPackageInProgress = false;
  formGroup: FormGroup<CreateOrderViewForm>;
  beamOrderLinesGridData: DataResult;
  planeLaminaThicknessOptions: Array<number>;
  beamLamminaDimensions: BeamLamminaDimension[];
  dimensions: BeamLamminaDimension[];
  altModels: IAltModelResponceDTO[] = [];
  selectedIDs = [];
  lastUpdated: Date;
  loading = false;
  reorderInProgress = false;
  beamLamminaDimensionColumnName = 'BeamLamminaDimensionId';
  selectedBeamLaminaDimensionId: number;
  disableOptimizerBtn: boolean;
  state: State = {
    skip: 0,
    take: this.take,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: [],
    group: []
  };

  beamMaterialTypes: IBeamMaterialType[] = [];

  pageSizes: PageSizeItem[] = [
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '150', value: 150 },
    { text: '200', value: 200 },
    { text: 'All', value: 0 }
  ];
  confirmedResetSettings: boolean = false;

  //Icons
  trashIcon: SVGIcon = trashIcon;
  arrowLeftIcon: SVGIcon = arrowLeftIcon;
  arrowRightIcon: SVGIcon = arrowRightIcon;
  warningTriangleIcon: SVGIcon = warningTriangleIcon;

  @ViewChild('autocomplete', { static: false })
  private autocomplete: AutoCompleteComponent;

  @ViewChild('grid')
  private grid: GridComponent;

  private editedRowIndex: number;
  private planeLaminaThickness = <IEntityProperty[]>[];
  private currentSelectedRows: Demandline[];
  private beamOrderLines: Demandline[];
  private currentQtyReproduce: number;
  private updateLaminaThicknessEntityPropertyValueValid: IEntityPropertyValueValid;
  private selectAllState: SelectAllCheckboxState = 'unchecked';
  private destroy$: Subject<void> = new Subject<void>();

  public rowActions = [

    {
      name: "App.RemoveByRow",
      svgIcon: trashIcon,
      click: (): void => {
        this.removeDemandByRowInProgress = true;
      },
    },
    {
      name: "App.RemoveByOrder",
      svgIcon: trashIcon,
      click: (): void => {
        this.removeDemandInProgress = true;
      },
    },
    {
      name: "App.RemoveByPackage",
      svgIcon: trashIcon,
      click: (): void => {
        this.removeDemandByPackageInProgress = true;
      },
    }
  ];

  constructor(
    private readonly beamOrderRowService: DeamndService,
    private readonly translate: TranslateService,
    private readonly entityPropertyService: EntityPropertyService,
    private readonly dimensionService: DimensionService,
    private readonly gluingPlanService: GluePlanService,
    private readonly notificationService: AppNotificationService,
    private readonly userSettingService: UserSettingService,
    private readonly demandSpecificationService: DemandSpecificationService,
    private readonly beamMaterialTypeService: BeamMaterialTypeService,
    private readonly orderViewService : OrderViewService
  ) {
  }

  async ngOnInit() {
    const thisPointer = this;
    this.loading = true;

    let userSettings = await firstValueFrom(this.getUserSettings());

    this.loading = false;

    if (this.filterOnSelectGluePlan && userSettings) {
      this.loading = true;
      this.gluingPlanService.selectedPlan.pipe(takeUntil(this.destroy$)).subscribe((plan: GluingPlan) => {
        this.loading = false;
        this.state.filter.filters = this.state.filter.filters.filter((x: any) => x.field !== this.beamLamminaDimensionColumnName);
        this.selectedBeamLaminaDimensionId = plan.beamLaminaDimentionId;

        if (this.selectedPlan?.gluingPlanID !== plan.gluingPlanID) {
          this.selectedIDs = [];
        }
      });
    }
    this.getDemands(this.selectedBeamLaminaDimensionId);
    this.initialLoad = false;

    this.selectedBeamLaminaDimensionId = this.selectedLamminaDimension?.beamLamminaDimensionID;

    let entityPropertys = await firstValueFrom(this.entityPropertyService.get());

    this.planeLaminaThickness = entityPropertys.filter((x) => x.code === PLANE_LAMINA_THICKNESS);

    this.planeLaminaThicknessOptions = this.planeLaminaThickness.map((x) => {
      return x.validValues.map((y) => {
        return y.valueDouble;
      });
    })[0];

    this.beamOrderRowService.unselectedOrderRow.subscribe((x) => {
      thisPointer.selectedIDs = thisPointer.selectedIDs.filter((id) => id !== x.rowId);
      const rows = this.beamOrderLines.filter((bol) => thisPointer.selectedIDs.find((id) => bol.rowId === id) > 0);
      this.selectRowCount = rows.length;
      this.beamOrderRowService.selecOrderRows(rows);
    });

    const dimensions = await firstValueFrom(this.dimensionService.getBeamLamminaDimentions(true));

    this.beamLamminaDimensions = dimensions;
    this.dimensions = dimensions;

    // This event will call after selected glue plan transport package demand api load data
    this.beamOrderRowService.getBeamOrderRows.pipe(takeUntil(this.destroy$)).subscribe((d) => this.onRowsUppdated(d));

    this.isRefreshGrid.pipe(takeUntil(this.destroy$)).subscribe(value => {
      /* to avoild multiple calls at page load */
      if (value && !this.initialLoad) {
        this.query();
      }
    });

    this.beamMaterialTypes = await firstValueFrom(this.beamMaterialTypeService.query());
  }

  private getDemands(beamLamminaDimensionID: number) {

    if (beamLamminaDimensionID > 0) {
      this.state.filter.filters = this.state.filter.filters.filter(x => x['field'] !== this.beamLamminaDimensionColumnName);
      this.state.filter.filters.push(<FilterDescriptor>{ field: this.beamLamminaDimensionColumnName, value: beamLamminaDimensionID.toString(), operator: 'eq' });
    }

    this.getUserGridSettings().pipe(takeUntil(this.destroy$)).subscribe(() => this.query());
  }

  private query(): Subscription {
    this.loading = true;
    if (this.state.filter.filters.find((x) => x['field'] === 'isActive') === undefined) {
      this.state.filter.filters.push(<FilterDescriptor>{ field: 'isActive', value: true, operator: 'eq' });
    }
    return this.beamOrderRowService.query(this.state, this.showPlanned, this.showOnlyWithNoGluPlan, this.siteId);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    event.stopPropagation();
    event.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isContinues = this.selectedBeamLaminaDimensionId !== undefined;
    /* if siteId changes load the complete grid */
    if (changes.siteId) {
      if (!changes.siteId.firstChange && !this.initialLoad) {
        this.query();
      }
    }

    if (changes.selectedPlan && !changes.selectedPlan.firstChange && isContinues) {
      let isIdenticalPlan = changes.selectedPlan.currentValue.gluingPlanID === changes.selectedPlan.previousValue?.gluingPlanID;
      let isDifferentGlueSet = changes.selectedPlan.currentValue.glueSetStateId !== changes.selectedPlan.previousValue?.glueSetStateId;
      let isSameDimensionPlan = changes.selectedPlan.currentValue.beamLaminaDimentionId === changes.selectedPlan.previousValue?.beamLaminaDimentionId;
      if ((isIdenticalPlan && isDifferentGlueSet) || (!isIdenticalPlan && isSameDimensionPlan)) {
        this.getDemands(this.selectedBeamLaminaDimensionId);
      }
    } else if (changes.uiQty) {
      this.loading = true;
      this.loadData(this.beamOrderLines);
      this.loadDataWithNewUiQty(this.beamOrderLines, changes.uiQty);
      this.loading = false;
    }
  }

  loadData(demands: Demandline[]) {
    if (demands) {
      demands.forEach(demand => demand.QtySumUI = 0);
      let isOptimizerGPSelected = this.selectedPlan?.gluingPlanID === 0;

      /* Find given beam order based for selected plan in uiQty(all utilized beams in gps) and update black qty */
      this.uiQty.forEach((d) => {
        const selectedPlanDemand = demands.find((k) => k.BeamOrderRowID === d.demandId && k.transportPackageId === d.transportPackageId &&
          (isOptimizerGPSelected ? this.selectedPlan?.gluePlanIndex === d.gluePlanIndex : this.selectedPlan?.gluingPlanID === d.gluePlanId));
        if (selectedPlanDemand) {
          selectedPlanDemand.QtySumUI = d.newQty;
        }
      });
    }

    this.beamOrderLines = demands;
    this.bindGrid(demands);
    this.loading = false
  }

  loadDataWithNewUiQty(demands: Demandline[], change: SimpleChange) {
    this.beamOrderLines =  this.orderViewService.loadDataWithNewUiQty(demands,change,this.selectedPlan);
    this.bindGrid(demands);
  }

  public getMaxCount(change: SimpleChange = null): any {
    return change.currentValue.length >= change.previousValue.length ? change.currentValue : change.previousValue;
  }

  public onRowsUppdated(demands: Demandline[]) {
    this.loading = false;
    this.loadData(demands);
    this.lastUpdated = new Date();
    const rows = this.beamOrderLines.filter((bol) => this.selectedIDs.find((id) => bol.rowId === id));
    this.selectRowCount = rows.length;
    this.forParentSelection(rows, []);
  }

  public updateSelectAllCheckboxState() {
    const len = this.selectedIDs.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
    } else if (len > 0 && len < this.beamOrderLines.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    const check: any = document.getElementsByClassName('group-check');
    if (checkedState === 'checked') {
      this.selectedIDs = this.beamOrderLines.map((y) => y.rowId);

      for (let c1 of check) {
        c1.checked = true;
      }
      this.selectAllState = 'checked';
    } else {
      this.selectedIDs = [];
      this.selectAllState = 'unchecked';
      for (let checkbox of check) {
        checkbox.checked = false;
      }
    }
  }

  public dataStateChange(event: DataStateChangeEvent): void {
    this.state = event;
    if (this.state.take === 0) {
      this.state.take = this.beamOrderLinesGridData.total;
    }
    if (!this.initialLoad) {
      this.loading = true;
      this.saveUserGridSettings();
      this.query();
    }
  }

  public selectGroup(event: Event, group: any): boolean {
    const groupedItems = this.getGroupedItemsRecursively(group.items);
    const groupedItemIds = groupedItems.map((i) => i.rowId);
    const selected = event.target['checked'];
    if (selected) {
      this.selectedIDs = this.selectedIDs.concat(groupedItemIds);
    } else {
      this.selectedIDs = this.selectedIDs.filter(function (value) {
        return groupedItemIds.indexOf(value) === -1;
      });
    }
    this.onSelect(null);
    return selected;
  }

  public onSelect(e: SelectionEvent) {
    const rows = this.beamOrderLines.filter((bol) => this.selectedIDs.find((rowId) => bol.rowId === rowId));

    this.currentSelectedRows = rows;
    this.selectRowCount = rows.length;
    this.beamOrderRowService.selecOrderRows(rows);
    this.updateSelectAllCheckboxState();
    this.disableOptimizerBtn = rows.some(x => x.beamMaterialType.isSpecialForm) || this.selectedIDs.length < 1;
  }

  private getGroupedItemsRecursively(items: any): any[] {
    let foundItems = [];
    if (items.length > 0 && items[0]['aggregates']) {
      items.forEach((item) => {
        foundItems = foundItems.concat(this.getGroupedItemsRecursively(item.items));
      });
    } else {
      foundItems = items;
    }
    return foundItems;
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.currentQtyReproduce = dataItem.transportPackageAllocation?.unitsExtra;

    this.closeEditor(sender);

    this.formGroup = createFormGroup(dataItem);

    this.queryAlternativeModels(this.formGroup.get('materialDescription').value);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public validate(currentQtyReproduce, updatedQtyReproduce) {
    if (updatedQtyReproduce > currentQtyReproduce) {
      this.notificationService.notifyErrorAppChanel('OrderViewTranslation.Qty_Reproduce_High');
      return false;
    } else if (updatedQtyReproduce < 0) {
      this.notificationService.notifyErrorAppChanel('OrderViewTranslation.Qty_Reproduce_Negative');
      return false;
    }
    return true;
  }

  public saveHandler({ sender, rowIndex, formGroup }): void {
    this.loading = true;
    const dl = formGroup.value;
    this.saveInProgress = true;
    const isValid = this.validate(this.currentQtyReproduce, dl.qtyReproduce);
    if (isValid) {
      this.beamOrderRowService
        .savePartial(<IDemandPartialDTO>{
          demandID: dl.DeamndId,
          lamminaPlaneThickness: +dl.LamminaPlaneThickness,
          lamminaPlaneWidth: dl.LamminaPlaneWidth,
          beamLamminaDimensionId: dl.beamLamminaDimensionId,
          materialIndex: dl.materialIndex,
          qtyReproduce: dl.qtyReproduce,
          transportPackageId: dl.transportPackageId
        })
        .subscribe({
          next: (d) => {
            this.saveInProgress = false;

            this.beamOrderLinesGridData.data
              .filter((b) => b.BeamOrderRowID === dl.DeamndId)
              .forEach((h) => {
                h.LamminaPlaneThickness = d.lamminaPlaneThickness;
                h.BeamLamminaDimensionID = d.beamLamminaDimensionId;
                h.LamminaPlaneWidth = d.lamminaPlaneWidth;
                h.materialIndex = d.materialIndex;
                h.materialDescription = d.materialDescription;
                h.transportPackageAllocation.unitsExtra = d.qtyReproduce;
              });

            this.beamOrderLines
              .filter((b) => b.BeamOrderRowID === dl.DeamndId)
              .forEach((h) => {
                h.DefaultLamminaPlaneThickness = d.lamminaPlaneThickness;
                h.BeamLamminaDimensionID = d.beamLamminaDimensionId;
                h.LamminaPlaneWidth = d.lamminaPlaneWidth;
                h.NumberOfLaminas = d.numberOfLamminas;
                h.materialIndex = d.materialIndex;
                h.materialDescription = d.materialDescription;
                h.deafultNumberOfBeamsPerBeamMaterial = d.numberOfBeamsPerBeamMaterial;
                h.transportPackageAllocation.unitsExtra = d.qtyReproduce;
              });

            // this.loadData();
            // this.onSelect(null);
            sender.closeRow(rowIndex);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
      this.saveInProgress = false;
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public updateLaminaValidValues(event, formGroup) {
    if (!event) {
      return;
    }
    const index = this.planeLaminaThickness[0].validValues.some((x) => x.valueDouble === Number(event));
    if (!index && formGroup.dirty) {
      this.updateLaminaThicknessEntityPropertyValueValid = <IEntityPropertyValueValid>{
        propertyCode: PLANE_LAMINA_THICKNESS,
        valueDouble: Number(event)
      };
      this.planeLaminaThickness[0].validValues.push(this.updateLaminaThicknessEntityPropertyValueValid);
    }
  }

  getPropertyValue(dataItem: any, propertyCode: string) {
    const p = dataItem.propertyCode === propertyCode;

    if (p) {
      return dataItem.displayValue;
    } else {
      return null;
    }
  }

  getBLDDescription(id: number): string {
    if (id) {
    return this.beamLamminaDimensions.find((bld) => bld.beamLamminaDimensionID === id).width + '';
    }
  }

  queryAlternativeModels(searchTerm: string) {
    if (searchTerm.length >= 3) {
      const entityState: EntityFilter = {} as EntityFilter;
      entityState.searchTermCode = searchTerm;
      entityState.take = 25;
      entityState.rawLamellaThickness = this.formGroup.get('rawLamellaThickness').value;
      entityState.siteId = this.formGroup.get('siteId').value;

      this.beamOrderRowService.getAlternateCodes(entityState).subscribe((result) => {
        this.altModels = result;
      });
    }
  }

  onMaterialSelected(material: IAltModelResponceDTO) {
    const entity = this.altModels.find((x) => x.index === material.index);

    this.formGroup.patchValue({
      materialIndex: entity.index,
      materialDescription: entity.materialDescription,
      LamminaPlaneThickness: entity.lamminaPlaneThickness ?? this.formGroup.get('LamminaPlaneThickness').value,
      beamLamminaDimensionId: entity.beamLamminaDimensionId ?? this.formGroup.get('beamLamminaDimensionId').value
    });
  }

  public onSelecttionChange(e) {
    this.forParentSelection(e.selectedRows, e.deselectedRows);
  }

  private forParentSelection(selectedData: any[], deselectedData: any[]) {
    selectedData.forEach(data => {
      const selectedGrp = this.beamOrderLines.filter(x => x.transportPackageAllocation.transportPackageID === data.dataItem.transportPackageAllocation.transportPackageID).map(x => {
        return x.rowId;
      });
      const check: any = document.getElementById('selectGrouptransportPackageCode' + data.dataItem?.transportPackageAllocation?.packageID);
      if (selectedGrp.every(item => this.selectedIDs.includes(item))) {
        check.indeterminate = false;
        check.checked = true;
      } else if (selectedGrp.some((item) => this.selectedIDs.includes(item))) {
        check.checked = false;
        check.indeterminate = true;
      } else {
        check.checked = false;
        check.indeterminate = false;
      }
    });
    deselectedData.forEach(data => {
      const deselectedGrp = this.beamOrderLines.filter(x => x.transportPackageAllocation.transportPackageID === data.dataItem.transportPackageAllocation.transportPackageID).map(x => {
        return x.rowId;
      });
      const check: any = document.getElementById('selectGrouptransportPackageCode' + data.dataItem?.transportPackageAllocation?.packageID);
      check.checked = false;
      check.indeterminate = deselectedGrp.some((item) => this.selectedIDs.includes(item));
    });
  }

  public openOptimizationEditor() {
    this.optimise.emit();
  }

  public resetUserSettings(): void {
    this.confirmedResetSettings = true;
  }

  confirmResetSettings(isConfirmed: boolean) {
    if (isConfirmed) {
      this.executeReset();
    }
    this.confirmedResetSettings = false
  }

  private executeReset() {
    this.userSettingService.delete(this.getUserGridSettingsKey()).subscribe();
    window.location.reload();
  }

  confirmRemoveDemand(confirmChange: boolean) {
    const ret: Array<{ text: string; values: any }> = new Array<{ text: string; values: any }>();
    const selectedDemandIDs = [];
    let rows: Demandline[];
    if (confirmChange) {
      if (this.currentSelectedRows.length > 0) {
        rows = this.currentSelectedRows;
      } else {
        rows = this.beamOrderLines.filter((bol) => this.selectedIDs.find((id) => bol.rowId === id));
      }
      if (this.removeDemandByPackageInProgress) {
        rows.forEach((element) => {
          selectedDemandIDs.push(element.transportPackageId);
        });
        this.beamOrderRowService.removeDemandByPackage(selectedDemandIDs).subscribe((t) => {
          this.selectedIDs = [];
          this.notificationService.notifyInfoAppChanel('Operative.PackageDeletedSuccessfully');
          this.dataStateChange(this.state as DataStateChangeEvent);
        });
      } else {
        rows.forEach((element) => {
          selectedDemandIDs.push(element.BeamOrderRowID);
        });
        this.beamOrderRowService.removeDemand(selectedDemandIDs, this.removeDemandByRowInProgress).subscribe((t) => {
          this.selectedIDs = [];
          ret.push({ text: 'Operative.DemandRecordDeleted', values: { noOfRecords: t } });
          this.deleteInfoNotification(ret);
          this.dataStateChange(this.state as DataStateChangeEvent);
        });
      }
    }
    this.removeDemandInProgress = false;
    this.removeDemandByRowInProgress = false;
    this.removeDemandByPackageInProgress = false;
  }

  private deleteInfoNotification(ret: { text: string; values: any }[]) {
    ret.forEach((info) => {
      this.notificationService.notifyInfoAppChanel(
        info.text,
        'Operative.DemandDeletedSuccessfully',
        info.values
      );
    });
  }

  public resizeGrid(value) {
    this.isLeftMajor = value;
    this.getUserGridSettings().subscribe(() => this.query());
    this.saveUserSettings();
    this.updateSize.emit(value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addOrViewSpecification(selectedDemand: Demandline): void {
    const dr = this.demandSpecificationService.openDialog(selectedDemand);
    dr.result.subscribe((response: IDemandDTO) => {
      if (response instanceof DialogCloseResult) {
        dr.close();
      } else if (response) {
        this.beamOrderLinesGridData.data
          .filter((lines) =>
            lines.items.filter((filteredlines) => filteredlines.BeamOrderRowID === selectedDemand.BeamOrderRowID)
              .forEach((demand) => {
                this.beamOrderRowService.setDemandFields(demand, response);
              })
          );
      }
    });
  }

  public onShowPlanedChanged(value: boolean) {
    this.loading = true;
    this.showPlanned = value;
    this.saveUserSettings();
    this.query();
  }

  public onShowWithNoGluPlan(value: boolean) {
    this.loading = true;
    this.showOnlyWithNoGluPlan = value;
    this.saveUserSettings();
    this.query();
  }

  saveUserSettings() {
    this.userSettingService.save<IOrderViewSetting>(
      UserSettingkeyEnum.order_view,
      <IOrderViewSetting>{
        isLeftMajor: this.isLeftMajor,
        showPlanned: this.showPlanned,
        showOnlyWithNoGluPlan: this.showOnlyWithNoGluPlan
      }
    ).subscribe();
  }

  getUserSettings(): Observable<any> {
    return this.userSettingService.get<IOrderViewSetting>(UserSettingkeyEnum.order_view).pipe(tap(settings => {
      if (settings) {
        this.applyUserSettings(settings);
      }
    }));
  }

  private applyUserSettings(settings: IOrderViewSetting): void {
    this.showPlanned = settings?.showPlanned ?? true;
    this.showOnlyWithNoGluPlan = settings?.showOnlyWithNoGluPlan ?? true;

    if (settings?.isLeftMajor && settings.isLeftMajor !== this.isLeftMajor) {
      this.isLeftMajor = settings.isLeftMajor;
      this.updateSize.emit(this.isLeftMajor);
    }
  }

  getUserGridSettings(): Observable<any> {
    return this.userSettingService.get<IPersistedGridState>(this.getUserGridSettingsKey()).pipe(tap(settings => {
      this.applyUserGridSettings(settings);
    }));
  }

  private applyUserGridSettings(settings: IPersistedGridState): void {
    if (!settings) {
      return;
    }
    this.grid.columns.filter(c => c.includeInChooser !== false).forEach(c => {
      c.hidden = true;
    });

    this.sortByOrderIndex(settings.columnSettings).forEach(colSetting => {
      let col;
      if (colSetting.field === this.translate.instant('App.Action')) {
        col = this.grid.columns.find(c => c.title === colSetting.field);
      } else {
        col = this.grid.columns.find(c => (c as ColumnComponent).field === colSetting.field);
      }

      if (col) {
        col.width = colSetting.width ?? col.width ?? 200;
        col.hidden = false;
      }
    });

    this.reorderInProgress = true;

    this.sortByOrderIndex(settings.columnSettings).forEach((colSetting, index) => {
      if (colSetting) {
        const col = this.grid.columns.find(c => (c as ColumnComponent).field === colSetting.field);

        if (col) {
          // Index + 1 to make sure column selector is zero
          this.grid.reorderColumn(col, index + 1, { before: true });
        }
      }
    });

    this.reorderInProgress = false;

    if (settings.state) {
      this.setDateFilter(settings.state, 'latestProductionDate');
      this.setDateFilter(settings.state, 'plannedProductionDate');
      this.state = settings.state;
    }
  }

  private setDateFilter(state: State, field: string) {
    const filter = state.filter.filters.find((x) => x['field'] === field);
    if (filter && filter['value']) {
      filter['value'] = new Date(filter['value']);
    }
  }

  private sortByOrderIndex<T extends { orderIndex?: number }>(items: T[]): T[] {
    return items.sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0));
  }

  private getUserGridSettingsKey(): string {
    return this.isLeftMajor ? UserSettingkeyEnum.grid_gluelam_demandList_exp : UserSettingkeyEnum.grid_gluelam_demandList;
  }

  public saveUserGridSettings(): void {
    if (!this.reorderInProgress) {
      setTimeout(() => {
        const settings = <IPersistedGridState>{
          state: this.state,
          columnSettings: this.grid.columns.filter(c => !c.hidden).map(col => {
            if ((col as ColumnComponent)?.field) {
              return <IPersistedColState>{
                field: (col as ColumnComponent).field,
                width: col.width,
                orderIndex: col.orderIndex
              };
            } else if (col.title === this.translate.instant('App.Action')) {
              return <IPersistedColState>{
                field: col.title,
                width: col.width,
                orderIndex: col.orderIndex
              };
            }
          })
        };
        settings.columnSettings = settings.columnSettings.filter(x => x);
        this.userSettingService.save<IPersistedGridState>(this.getUserGridSettingsKey(), settings).subscribe();
      }, 500);
    }
  }

  private bindGrid(demands: Demandline[]) {
    this.beamOrderLinesGridData = process(demands, { group: this.state.group });
    this.beamOrderLinesGridData.total = this.beamOrderRowService.totalCount;
    this.beamOrderLinesGridData.data.forEach(x => {
      x.latestProductionDate = parseDate(x.latestProductionDate, DateHelper.dateFormat_ddMMyy);
      x.plannedProductionDate = parseDate(x.plannedProductionDate, DateHelper.dateFormat_ddMMyy);
    })
  }
}
