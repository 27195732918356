import { BeamMaterialGlueSet, BeamMaterialGlueSetState } from '@app/core/models/beam-material-glue-set.model';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { Entity } from '@app/modules/model/service/graph.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderView } from '@app/modules/gluelam/controls/order-view/order-view.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./../planner/planner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host:{
    class :"d-flex-col-component"
  }
})
export class OperatorComponent implements OnInit {
  @ViewChild(OrderView) orderView: OrderView;
  selectedGluingPlanId: number;
  glusets: BeamMaterialGlueSet[] = [];
  states: BeamMaterialGlueSetState[] = [];
  selectedState: BeamMaterialGlueSetState;
  // All beam ordered lines. These are sent to order-row-filter view, which filters them and then set to app-order-view.
  beamOrderLines: Demandline[] = [];
  // All the filtered beam lines
  beamOrderLinesFilterd: Demandline[] = [];
  LatestDeploymentDate: Date;
  ready = false;
  //selected Lammina dimentions
  selectedLamminaDim: BeamLamminaDimension;
  selectedBeamOrderLines: Demandline[] = [];
  machines: Entity[] = [];
  selectedMachine: Entity;
  gsWidth:number = 300;
  interval: any;
  isOperator: boolean = true;
  selectedPlan: GluingPlan;

  public get BeamMaterialGlueSetSuggestions(): BeamMaterialGlueSet[] {
    if (this.selectedState && this.selectedState.name !== 'All') {
      return this.glusets.filter((x) => {
        return (
          x.beamMaterialGlueSetState.glueSetStateId ===
          this.selectedState.glueSetStateId
        );
      });
    }
    return this.glusets;
  }

  public set BeamMaterialGlueSetSuggestions(value: BeamMaterialGlueSet[]) {
    this.glusets = value;
  }

  @ViewChild('bmgsContainer') bmgsContainer: ElementRef;

  constructor(
    public notificationService: AppNotificationService,
    public toastrService: ToastrService,
    public bmgsService: GlueSetService,
    public dimensionService: DimensionService,
    public beamOrderRowService: DeamndService,
    public translate: TranslateService,
    public conf: ConfigurationService,
    public gluingPlanService: GluePlanService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.conf.load().then(() => this.SetUpSubscriptions());
  }

  SetUpSubscriptions() {

    this.beamOrderRowService.selectedOrderRows.subscribe((rows) => {
      this.spinner.hide();
      this.selectedBeamOrderLines = rows;
    });

    this.gluingPlanService.selectedPlan.subscribe((plan: GluingPlan) => {
      console.log('selectedPlan Received');
      this.spinner.hide();
      this.selectedGluingPlanId = plan.gluingPlanID;
      this.selectedPlan = plan;
      this.GetSavedGlueSetsForGluingPlan(this.selectedGluingPlanId);
    });
  }

  onDataLoadSuccessfulGlueSets(glueSets: BeamMaterialGlueSet[]) {
    glueSets.forEach((bmgs) => {
      if (bmgs.errorMessageText) {
        this.toastrService.error(
          bmgs.errorMessageText,
          'Error on: ' + bmgs.beamMaterialGlueSetID
        );
      }
    });

    this.BeamMaterialGlueSetSuggestions = glueSets;

    this.BeamMaterialGlueSetSuggestions = [
      ...this.BeamMaterialGlueSetSuggestions
    ];

    // if (this.selectedLamminaDim.Width > 1) {
    //   this.AddEmptyBmgs();
    // } else
    if (glueSets.length === 0) {
      this.notificationService.notifyWarningAppChanel(
        'Det finns inga sparade limmningar, skapa en' +
        'limmning med en lamell innan du väljer alla rader!',
        'Inga Sparade Limmningar'
      );
    }

    this.populateState();
    this.spinner.hide();
  }

  GetSavedGlueSets() {
    this.bmgsService
      .getGlueSets(
        this.selectedLamminaDim.beamLamminaDimensionID
      )
      .subscribe((beamMaterialGlueSets) =>
        this.onDataLoadSuccessfulGlueSets(beamMaterialGlueSets)
      );
  }

  setSelectGluingPlan(id: number) {
    this.spinner.show();
    this.selectedGluingPlanId = id;
    this.GetSavedGlueSetsForGluingPlan(id);
  }

  GetSavedGlueSetsForGluingPlan(id: number) {
    this.spinner.show();
    this.bmgsService.getGlueSetByGluingPlanId(id).subscribe((beamMaterialGlueSets) => {
      if (beamMaterialGlueSets && beamMaterialGlueSets.length > 0) {
        this.onDataLoadSuccessfulGlueSets(beamMaterialGlueSets);
      } else {
        this.spinner.hide();
      }
    });
  }

  private populateState() {
    this.states = [];
    const allState = new BeamMaterialGlueSetState(
      -1,
      'All',
      'All PlaceHolder',
      false,
      []
    );
    this.states.push(allState);

    this.BeamMaterialGlueSetSuggestions.forEach((bmgs) => {
      if (
        !this.states.find((x) => x.name === bmgs.beamMaterialGlueSetState.name)
      ) {
        this.states.push(bmgs.beamMaterialGlueSetState);
      }
    });

    this.selectedState = allState;
  }
}
