import {
  BeamMaterialGlueSetEditor,
  KeyValueDisplayComponent
} from '@app/modules/gluelam/controls/beam-material-gluset/beam-material-glueset.component';
import { BeamMaterialGlueSetStaticalEditor, KeyValueDisplayStaticalComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/beam-material-gluset-statical/beam-material-gluset-statical.component';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GluePlanBindingDirective, GluePlansHistoryComponent } from './glue-plans-history/glue-plans-history.component';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { AppControlsModule } from '@app/modules/app-controls/app-controls.module';
import { BMGSprintComponent } from '@app/modules/gluelam/controls/print/bmgs-print.component';
import { BMLMenuComponent } from '@app/modules/gluelam/controls/beam-material-gluset/bml-menu.component';
import { BMprintComponent } from '@app/modules/gluelam/controls/print/bm-print.component';
import { BeamHistoryComponent } from './beam-history/beam-history.component';
import { BeamMaterialEditor } from '@app/modules/gluelam/controls/beam-material-gluset/beam-material.component';
import { BeamMaterialGlusetStaticalDialogComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/beam-material-gluset-statical-dialog/beam-material-gluset-statical-dialog.component';
import { BeamMaterialLineEditor } from '@app/modules/gluelam/controls/beam-material-gluset/beam-material-line.component';
import { BeamMaterialLineEditorService } from '@app/modules/gluelam/controls/beam-material-gluset/beam-material-line-menu.service';
import { BeamMaterialService } from '@app/modules/gluelam/services/beam-material.service';
import { BeamMaterialTypeService } from '@app/core/services/http-services/gluelam/beam-material-type.service';
import { BeamSplitViewModalComponent } from '@app/modules/gluelam/controls/beam-split-view-modal/beam-split-view-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CopyService } from '@app/core/services/custom-services/copy.service';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DraggableDirective } from '@app/core/directives/draggable-directive';
import { GluePLanLayerdViewModelComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/glue-plan-layerd-view-model/glue-plan-layerd-view-model.component';
import { GluePlanViewStaticalComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/glue-plan-view-statical/glue-plan-view-statical.component';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GlueSetStateService } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { GluelamRoutingModule } from '@app/modules/gluelam/gluelam-routing.module';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { GlueplanPrintFirstComponent } from '@app/modules/gluelam/glueplan-print-first/glueplan-print-first.component';
import { GlueplanPrintSecondComponent } from '@app/modules/gluelam/glueplan-print-second/glueplan-print-second.component';
import { GlueplanPrintThirdComponent } from '@app/modules/gluelam/glueplan-print-third/glueplan-print-third.component';
import { GluesetViewComponent } from '@app/modules/gluelam/glueset-view/glueset-view.component';
import { GluesetsViewModelComponent } from '@app/modules/gluelam/gluesets-view-model-component/gluesets-view-model.component';
import { GluesetsViewUrlComponent } from '@app/modules/gluelam/gluesets-view-modal/gluesets-view.component';
import { GluingPlanLayeredViewComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/gluing-plan-layered-view/gluing-plan-layered-view.component';
import { GluingPlanModalNgComponent } from '@app/modules/gluelam/controls/gluing-plan-modal-ng/gluing-plan-modal-ng.component';
import { GluingPlansComponent } from '@app/modules/gluelam/controls/gluing-plans/gluing-plans.component';
import { GlulamSpecComponent } from '@app/modules/gluelam/controls/glulam-spec/glulam-spec.component';
import { HttpClientModule } from '@angular/common/http';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ManualBeamMaterialDialogComponent } from '@app/modules/gluelam/controls/manual-beam-material-dialog/manual-beam-material-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OperatorComponent } from '@app/modules/gluelam/operator/operator.component';
import { OptimazationOrderEditor } from '@app/modules/gluelam/controls/optimization-order/optimization-order.component';
import { OptimazationService } from '@app/core/services/http-services/gluelam/optimazation.service';
import { OrderView } from '@app/modules/gluelam/controls/order-view/order-view.component';
import { PlannerComponent } from '@app/modules/gluelam/planner/planner.component';
import { PressbedPlanningViewComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/pressbed-planning-view/pressbed-planning-view.component';
import { PressbedPlanningViewService } from '@app/core/services/http-services/gluelam/pressbed-planning-view.service';
import { ProductionModelService } from '@app/core/services/http-services/gluelam/production-model.service';
import { SharedModule } from '@app/shared/shared.module';
import { StaticalOptimazationOrderEditor } from '@app/modules/gluelam/controls/statical-optimization-order/statical-optimization-order.component';
import { StaticalPlannerComponent } from '@app/modules/gluelam/statical-planner/statical-planner.component';
import { StaticalSchedulerComponentV2 } from '@app/modules/gluelam/statical-scheduler-v2/statical-scheduler-v2.component';
import { StaticalSchedulerService } from '@app/core/services/http-services/gluelam/statical-scheduler.service';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { OrderViewService } from './services/order-view.service';
import { ZulagenDialogComponent } from '@app/modules/gluelam/controls/zulagen-dialog/zulagen-dialog.component';

// Services
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    DraggableDirective,
    PlannerComponent,
    StaticalPlannerComponent,
    OrderView,
    OptimazationOrderEditor,
    BMGSprintComponent,
    BMprintComponent,
    BeamMaterialGlueSetEditor,
    BeamMaterialGlueSetStaticalEditor,
    BeamMaterialEditor,
    BeamMaterialLineEditor,
    BMLMenuComponent,
    SafeHtmlPipe,
    KeyValueDisplayComponent,
    KeyValueDisplayStaticalComponent,
    GluingPlansComponent,
    GluingPlanModalNgComponent,
    OperatorComponent,
    BeamHistoryComponent,
    BeamSplitViewModalComponent,
    GlueplanPrintFirstComponent,
    GlueplanPrintSecondComponent,
    GluePlansHistoryComponent,
    GluePlanBindingDirective,
    GluesetsViewUrlComponent,
    GluesetViewComponent,
    GluesetsViewModelComponent,
    GluePlanViewStaticalComponent,
    GluingPlanLayeredViewComponent,
    GlulamSpecComponent,
    PressbedPlanningViewComponent,
    StaticalOptimazationOrderEditor,
    StaticalOptimazationOrderEditor,
    StaticalSchedulerComponentV2,
    GluePLanLayerdViewModelComponent,
    GlueplanPrintThirdComponent,
    ManualBeamMaterialDialogComponent,
    BeamMaterialGlusetStaticalDialogComponent,
    ZulagenDialogComponent
  ],
  imports: [
    GluelamRoutingModule,
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    KendoImportModule,
    TranslateModule,
    ReactiveFormsModule,
    AppControlsModule,
    SharedModule,
    DialogsModule,
    LayoutModule
  ],
  exports: [PlannerComponent, BeamMaterialGlueSetEditor],
  providers: [
    GlueSetService,
    DeamndService,
    DimensionService,
    BeamMaterialLineEditorService,
    OptimazationService,
    CopyService,
    PressbedPlanningViewService,
    GlueSetStateService,
    ProductionModelService,
    GluelamStaticalMediatorService,
    OrderViewService,
    BeamMaterialService,
    StaticalSchedulerService,
    DatePipe,
    BeamMaterialTypeService
  ]
})
export class GluelamModule {}
