import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { BeamMaterialLinesService, IBeamMaterialLineInfo, IBeamMaterialLineState, IBeamStateReport } from '@app/core/services/http-services/gluelam/beam-material-lines.service';
import { SVGIcon, cancelIcon } from '@progress/kendo-svg-icons';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { ProductionStateGroupCode } from '@app/core/models/production-state-group-code-enum';

@Component({
  selector: 'app-beam-history',
  templateUrl: './beam-history.component.html',
  styleUrls: ['./beam-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BeamHistoryComponent implements OnInit {
  states: IBeamMaterialLineState[] = [];
  filter: CompositeFilterDescriptor;
  view: Observable<GridDataResult>;
  gluePlanId: string;
  beamMaterialLineID: string;
  beamOrderRowID: string;
  state: State = {
    skip: 0,
    take: 25,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [
        { field: 'gluePlanId', operator: 'contains', value: '' },
        { field: 'beamMaterialLineID', operator: 'contains', value: '' },
        { field: 'orderNo', operator: 'contains', value: '' },
        { field: 'orderRowNo', operator: 'contains', value: '' },
        { field: 'transportPackageId', operator: 'contains', value: '' },
        { field: 'customerDesc', operator: 'contains', value: '' },
        { field: 'mainProduct', operator: 'contains', value: '' },
        { field: 'quality', operator: 'contains', value: '' },
        { field: 'constructionCertification', operator: 'contains', value: '' },
        { field: 'width', operator: 'contains', value: '' },
        { field: 'height', operator: 'contains', value: '' },
        { field: 'length', operator: 'contains', value: '' },
        { field: 'glueSetId', operator: 'contains', value: '' },
        { field: 'stateId', operator: 'contains', value: '' }
      ]
    },
     sort:[]
  };

    cancelIcon:SVGIcon = cancelIcon;
    deniedRoles = [UserRoleEnum.Operator];
    productionStateGroupCodeEnum: typeof ProductionStateGroupCode = ProductionStateGroupCode;

  constructor(private bmlService: BeamMaterialLinesService) {
    this.view = bmlService;
    this.bmlService.query(this.state);
  }

  ngOnInit(): void {
    this.bmlService.getStates().subscribe(r => {
      this.states = r;
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.bmlService.query(state);
  }

  public getStateColor(stateId: number) {
    switch (stateId) {
      case ProductionStateGroupCode.NOTSTARTED: return '#6c757d';
      case ProductionStateGroupCode.STARTED: return '#007bff';
      case ProductionStateGroupCode.DONE: return '#28a745';
      case ProductionStateGroupCode.REJECTED: return '#dc3545';
    }
  }

  onReject(beam: IBeamMaterialLineInfo) {
    this.bmlService.reject(<IBeamStateReport>{
      beamMaterialLineId: beam.beamMaterialLineID,
      code: 'REJECT'
    }, this.state);
  }
}
