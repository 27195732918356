import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { IDemandLamellaSpecificationDTO } from '@app/core/models/demand-lamella-specification.model';
import { CreateZulagenForm } from '@app/core/models/forms/gluelam/zulagen-form.model';
import { DemandSpecificationService } from '@app/core/services/http-services/aot/demand-specification.service';
import { IBeamMaterial } from '@app/core/services/http-services/gluelam/glue-set.service';
import { DialogRef } from '@progress/kendo-angular-dialog';


  const createFormGroup = (bm: BeamMaterial) => new FormGroup<CreateZulagenForm>({
  additionalLamellaId: new FormControl<number>(bm.additionalLamellaId , [Validators.required]),
  additionalLamellasTop: new FormControl<number>(bm.additionalLamellasTop, [Validators.required]),
  additionalLamellasBottom: new FormControl<number>(bm.additionalLamellasBottom, [Validators.required]),
});

@Component({
  selector: 'app-zulagen-dialog',
  templateUrl: './zulagen-dialog.component.html',
  styleUrls: ['./zulagen-dialog.component.css']
})
export class ZulagenDialogComponent {
  zulagenForm: FormGroup;
  beamMaterial:IBeamMaterial
  noOfLamellas: number[] = [0, 1, 2, 3, 4, 5];
  demandLamellas:IDemandLamellaSpecificationDTO[];
  content: BeamMaterialLine;

  constructor(@Inject(DialogRef) public data: { demandId: number,beamMaterial: BeamMaterial},
    private readonly dialogRef: DialogRef,
    private readonly demandSpecificationService:DemandSpecificationService
  ){}

  ngOnInit(): void {
    this.buildForm();
    this.getdemandLamellas();
  }

  buildForm() {
    this.zulagenForm = createFormGroup(this.data.beamMaterial);
  }

  getdemandLamellas() {
    this.demandSpecificationService.get(this.data.demandId).subscribe(lamellas => {
      this.demandLamellas = lamellas.filter((value, index, self) =>
        self.findIndex(t => t.materialIndex === value.materialIndex) === index
      );
    });
  }


  addZulagenConfig() {
    const formValue = this.zulagenForm.value;

    const beamMaterial: IBeamMaterial = <IBeamMaterial>{
      additionalLamellaId: formValue.additionalLamellaId,
      additionalLamellasTop: formValue.additionalLamellasTop,
      additionalLamellasBottom: formValue.additionalLamellasBottom
    };
    this.dialogRef.close(beamMaterial);
  }


  onCancel() {
    this.dialogRef.close(false);
  }

}
