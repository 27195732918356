import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/services/auth-service/auth.guard';
import { BMGSprintComponent } from '@app/modules/gluelam/controls/print/bmgs-print.component';
import { BeamHistoryComponent } from '@app/modules/gluelam/beam-history/beam-history.component';
import { GluePlansHistoryComponent } from '@app/modules/gluelam/glue-plans-history/glue-plans-history.component';
import { GlueplanPrintFirstComponent } from '@app/modules/gluelam/glueplan-print-first/glueplan-print-first.component';
import { GlueplanPrintSecondComponent } from '@app/modules/gluelam/glueplan-print-second/glueplan-print-second.component';
import { GlueplanPrintThirdComponent } from '@app/modules/gluelam/glueplan-print-third/glueplan-print-third.component';
import { GluesetsViewModelComponent } from '@app/modules/gluelam/gluesets-view-model-component/gluesets-view-model.component';
import { GluesetsViewUrlComponent } from '@app/modules/gluelam/gluesets-view-modal/gluesets-view.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { OperatorComponent } from '@app/modules/gluelam/operator/operator.component';
import { PlannerComponent } from '@app/modules/gluelam/planner/planner.component';
import { StaticalPlannerComponent } from '@app/modules/gluelam/statical-planner/statical-planner.component';
import { StaticalSchedulerComponentV2 } from '@app/modules/gluelam/statical-scheduler-v2/statical-scheduler-v2.component';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { ApplicationKey } from '@app/core/models/application.constant';

const appRoutes: Routes = [
  {
    path: 'glulam/beams',
    component: BeamHistoryComponent,
    canActivate: [ AuthGuard, MsalGuard],
        data: {
           forceSelect: true,
          [ApplicationKey.ExpectedRoles]: [
            UserRoleEnum.Operator
          ]
        }
  },
  {
    path: 'glulam/operator',
    component: OperatorComponent,
    canActivate: [ AuthGuard, MsalGuard],
        data: {
           forceSelect: true,
          [ApplicationKey.ExpectedRoles]: [
            UserRoleEnum.Operator
          ]
        }
  },

  { path: 'glulam/plan-print-first/:id', component: GlueplanPrintFirstComponent, canActivate: [MsalGuard, AuthGuard] },
  {
    path: 'glulam/plan-print-second/:id',
    component: GlueplanPrintSecondComponent,
    canActivate: [MsalGuard, AuthGuard]
  },
  { path: 'glulam/gluset-print/:id', component: BMGSprintComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/gluplam-view/:id', component: GluesetsViewUrlComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/glulam-view-model/', component: GluesetsViewModelComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/solution/:gluePlanId/:solutionId', component: PlannerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/glueplans', component: GluePlansHistoryComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true } },
  { path: 'glulam/statical', component: StaticalPlannerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/:id', component: PlannerComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/statical/scheduler-v2', component: StaticalSchedulerComponentV2, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam/glueplan-print-third/:id', component: GlueplanPrintThirdComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'glulam', component: PlannerComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true }}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class GluelamRoutingModule {}
