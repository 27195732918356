
export enum BeamMaterialType {
  UNKNOWN,
  NORMAL,
  SPECIAL_FORM,
  ANGELD_CUT,
  SPLIT_WIDTH,
  SPLIT_HIGHT,
  ANGELD_HIGHT_SLICE,
  KVH
}