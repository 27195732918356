import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.css']
})

export class QrCodeGeneratorComponent implements OnChanges {
  @Input() qrData: Record<string, any> = {}; // Dynamic input object
  @Input() showInputValues: boolean = false;

  qrCodeValue: string = '';
  displayInputValues: string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.qrData && Object.keys(this.qrData).length > 0) {
      this.qrCodeValue = Object.entries(this.qrData)
        .filter(([_, value]) => value !== null && value !== undefined) // Exclude null or undefined values
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`) // Encode key-value pairs
        .join('&');

      this.displayInputValues = Object.values(this.qrData)
        .filter(value => value !== null && value !== undefined)
        .join(' / ');
    } else {
      this.qrCodeValue = '';
    }
  }
}

