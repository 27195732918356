import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { CardItem, IDashTileData, IDashTileMember } from '../models/CardItem.model';
import { Subject, takeUntil } from 'rxjs';

import { CardItemService } from '../services/dashbord-card-item.service';

// import { ngxLoadingAnimationTypes } from 'ngx-spinner';
@Component({
  selector: 'app-dashboard-layout-card',
  template: `
  <div>
  <kendo-loader *ngIf="loading"
              [type]="'pulsing'"
              [themeColor]="'primary'"
              [size]="'large'"
            >
            </kendo-loader>
    <ng-template #dynamicComponent></ng-template>
  </div>
    `,
  styleUrls: ['./dashboard-layout.component.css'],


})
export class DashboardLayoutCardComponent implements AfterViewInit {

  @Input() card: CardItem;

  @Input() update: Subject<IDashTileData>;

  @ViewChild('dynamicComponent', { read: ViewContainerRef }) dynamicComponent: ViewContainerRef;

  public loading:boolean = false

  private tielComponent:IDashTileMember

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    public cardService: CardItemService) {

    console.log("app-dashboard-layout-card Const");
  }
  ngAfterViewInit(): void {
    this.loadComponent();
  }
  loadComponent() {

    const componentRef = this.dynamicComponent.createComponent(this.card.component);

    this.tielComponent = componentRef.instance as IDashTileMember;

    this.tielComponent.data = this.card.data;

    this.tielComponent.subscribeUpdate(this.update);

    this.tielComponent.loadingCallback().pipe(takeUntil(this.componentDestroyed$)).subscribe(l => {
      this.loading = l;
    })

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}




