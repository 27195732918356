import { NotificationComponent, NotificationDisplayComponent } from './notification/notification.component';

import { BrowserModule } from '@angular/platform-browser';
import { CardItemService } from './services/dashbord-card-item.service';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DashboardLayoutCardComponent } from './dashboard-layout/dashboard-card-component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DiagramComponent } from './diagram/diagram.component';
import { EntityQtyComponent } from './entity-qty/entity-qty.component';
import { GLTkpiComponent } from './glt-kpi/glt-kpi.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PieDiagramComponent } from './pie-diagram/pie-diagram.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DiagramComponent, DashboardLayoutComponent, GLTkpiComponent, PieDiagramComponent, NotificationDisplayComponent, NotificationComponent, EntityQtyComponent, DashboardLayoutCardComponent],
  imports: [
    ReactiveFormsModule,
    DashboardRoutingModule,
    KendoImportModule,
    TranslateModule,
    BrowserModule,
    ChartsModule,
    LayoutModule,
    NgxSpinnerModule,
  ],
  providers: [
    CardItemService
  ],
  bootstrap: [GLTkpiComponent]
})
export class DashboardModule { }
