import { Observable, Subject } from 'rxjs';

import { Type } from '@angular/core';

export class CardItem {
  constructor(public component: Type<IDashTileMember>,
    public title:string,
    public cols:number,
    public rows:number,
    public backgroundColor:string = null,
    public showTitle:boolean = true,
    public col?:number,
    public row?:number,
    public data?:any
  ) {}
}

export interface IDashTileMember {
  subscribeUpdate(update: Subject<IDashTileData>)
  loadingCallback():Subject<boolean>
  errorCallback():Subject<boolean>
  data:any
}

export interface IDashTileData {
  start:Date;
  end:Date;
}
