import { HttpClient, HttpParams } from '@angular/common/http';

import { IGlueLamSchedulerSearchCriteriaDTO } from '@app/core/models/glue-lam-scheduler-search-criteria-dto.model';
import { IStaticalSchedulerResultDTO } from '@app/core/models/glue-lam-scheduler-result-dto';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { DateHelper } from '@app/shared/helpers/date-helper';

@Injectable()
export class StaticalSchedulerService {
  private gluplanUri = `${environment.apiUrl}StaticalScheduler`;

  constructor(
    public http: HttpClient
  ) {}

  get(searchCriteria: IGlueLamSchedulerSearchCriteriaDTO) {

    let params = new HttpParams();

    params = params
      .append('machineId', searchCriteria.machineId)
      .append('startDate', DateHelper.fromDateISOFormat(searchCriteria.startDate))
      .append('endDate', DateHelper.toDateISOFormat(searchCriteria.endDate))
    ;

    return this.http.get<IStaticalSchedulerResultDTO>(this.gluplanUri, { params: params });
  }
}
