import { ActivatedRoute, Router } from '@angular/router';
import { CardItemService, DashbordDef, DashbordDefs } from '../services/dashbord-card-item.service';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { GltSearch } from '@app/core/models/forms/analytics/glt-search-form';
import { IDashTileData } from '../models/CardItem.model';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {

  form:FormGroup = new FormGroup<GltSearch>({
    start: new FormControl<Date>(new Date()),
    end: new FormControl<Date>(this.addDays(new Date(), -365))
  });


  componentDestroyed$: Subject<boolean> = new Subject()

  load() {
    this.update.next(this.form.value)
  }

  interval: any;

  rowHeight: 200;
  colWidth: 200;

  def: DashbordDef;
  settings = {
    rowhight: 200,
    numberOfCols: 4
  };



 addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

  update: Subject<IDashTileData> = new Subject<IDashTileData>();

  injector: Injector;

  constructor(
    public cardService: CardItemService,
    public activeRoute: ActivatedRoute,
    public router: Router) {
    activeRoute.params.pipe(takeUntil(this.componentDestroyed$)).subscribe(val => {
      const id = val['id'] ? +val['id'] : DashbordDefs.Generic;
      this.cardService.getCardItems(id).subscribe(def => {
        if (def) {
          this.def = def;
        } else {
          this.router.navigate(['/page-not-found']);
        }
      });

    });
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  ngOnInit(): void {

    console.log('constructor ngOnInit')
  }
}
