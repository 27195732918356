<div class="k-p-1">
  <form class="formcls" id="zulagenForm" [formGroup]="zulagenForm">
    <div class="row k-w-full k-mb-3">
      <kendo-formfield appearance="standard" class="k-w-full flex-grow-1">
        <kendo-label translate>App.Material</kendo-label>
        <kendo-dropdownlist class="k-w-full" [data]="demandLamellas" textField="materialDescription"
          valueField="materialIndex" formControlName="additionalLamellaId" [valuePrimitive]="true">
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div class="row k-w-full flex-grow-1">
      <kendo-formfield appearance="standard" class="k-w-full flex-grow-1">
        <kendo-label translate>GlueSet.AdditionalLamellasTop</kendo-label>
        <kendo-dropdownlist class="k-w-full" [data]="noOfLamellas" formControlName="additionalLamellasTop"
          [valuePrimitive]="true">
        </kendo-dropdownlist>
      </kendo-formfield>

      <kendo-formfield appearance="standard" class="k-w-full flex-grow-1">
        <kendo-label translate>GlueSet.AdditionalLamellasBottom</kendo-label>
        <kendo-dropdownlist class="k-w-full" [data]="noOfLamellas" formControlName="additionalLamellasBottom"
          [valuePrimitive]="true">
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <kendo-dialog-actions layout="end">
      <button kendoButton [disabled]="!zulagenForm.valid" [primary]="true" (click)="addZulagenConfig()">
        {{ 'App.Add' | translate }}
      </button>
      <button kendoButton (click)="onCancel()" themeColor="error">
        {{ 'App.Cancel' | translate }}
      </button>

    </kendo-dialog-actions>
  </form>
</div>
