import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, firstValueFrom } from 'rxjs';

import { BeamMaterialTypeService } from '@app/core/services/http-services/gluelam/beam-material-type.service';
import { IBeamMaterialType } from '@app/core/services/http-services/gluelam/glue-set.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Demandline } from '@app/core/models/demand-line.model';
import { BeamMaterialType } from '@app/core/models/beam-material-type-enum';

marker('App.UnitCode');

@Component({
  selector: 'app-select-beam-material-type',
  template: `
            <kendo-formfield>
            <kendo-label text="{{ 'OrderViewTranslation.BeamMaterialType' | translate }}"></kendo-label>
            <kendo-dropdownlist 
              [data]="beamMaterialTypes" 
              valueField="beamMaterialTypeID" 
              textField="name"
              (valueChange)="onChangedVal($event)" 
              [valuePrimitive]="true" 
              [loading]="loading"
              [(value)]="selectedTypeId" 
            >
            </kendo-dropdownlist>
          </kendo-formfield>
`,
  styleUrls: ['./select-beam-material-type.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectBeamMaterialTypeComponent
    }
  ]
})
export class SelectBeamMaterialTypeComponent implements OnInit, ControlValueAccessor, OnDestroy {
  beamMaterialTypes: IBeamMaterialType[] = [];
  selectedTypeId:number;
  loading:boolean = true;

  @Input() showLable:boolean = false;
  @Input() disableTextbox:boolean = false;
  @Input() hiddenTypeIds:number[] = [];
  @Input() isManualBeam:boolean = false;
  @Input() demandLines: Demandline[] = [];
  @Output() selectedBeamMaterialType: EventEmitter<IBeamMaterialType> = new EventEmitter<IBeamMaterialType>();

  private onChanged: Function = () => {};
  private onTouched: Function = () => {};
  private destroy$ = new Subject<void>();

  constructor(private readonly beamMaterialTypeService: BeamMaterialTypeService) { }

  async ngOnInit() {
    this.beamMaterialTypes = await firstValueFrom(this.beamMaterialTypeService.query());
    if(this.isManualBeam) {
      this.filterManualBeamMaterialTypes();
    }
    this.loading = false
  }

  onChangedVal(selectedTypeId:number) {
    this.selectedTypeId = selectedTypeId;
    this.onTouched();
    this.onChanged(selectedTypeId);

    const bmt = this.beamMaterialTypes.find(x => x.beamMaterialTypeID === selectedTypeId);
    this.selectedBeamMaterialType.emit(bmt);
  }

  writeValue(id: number): void {
    this.selectedTypeId = id;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private filterManualBeamMaterialTypes()  {
    this.beamMaterialTypes = this.beamMaterialTypes.filter(type => type.isManual);
    this.setBeamMaterialTypeId();
  }

  private setBeamMaterialTypeId() {
    const beamMaterialTypeId = this.getBeamMaterialTypeId();
    this.onChangedVal(beamMaterialTypeId);
  }

  private getBeamMaterialTypeId() : number {
    const uniqueBeamMaterialTypeId = [...new Set(this.demandLines.map(item => item.beamMaterialType.beamMaterialTypeID))];
    
    if (uniqueBeamMaterialTypeId.length === 1 && this.beamMaterialTypes.some(x => x.beamMaterialTypeID === uniqueBeamMaterialTypeId[0]))
    {          
      return uniqueBeamMaterialTypeId[0];
    }
    
    return this.beamMaterialTypes.filter(x => x.externalId === BeamMaterialType.UNKNOWN)[0].beamMaterialTypeID;
  }
}
