/* eslint-disable no-unused-vars */

import { HttpClient, HttpParams } from '@angular/common/http';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { IEntity } from '@app/core/services/http-services/model/entity.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IDemandDTO, ITransportPackageAllocation } from './demand.service';
import { BeamMaterialGlueSet, BeamMaterialGlueSetCreator, BeamMaterialGlueSetSequence, BeamMaterialGlueSetState, IBeamMaterialGlueSets } from '@app/core/models/beam-material-glue-set.model';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IPlannedLamellasWithBoxInfo } from '@app/core/models/glueset-lamella-instruction.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { ProductionStateGroupCode } from '@app/core/models/production-state-group-code-enum';

marker('GlueSet.UNDER_MIN_LENGTH');
marker('GlueSet.WARNING_COMBININING_SPLIT_AND_NOSPLIT');
marker('GlueSet.WARNING_HIGH_LENGTH_DIFFERNCE_IN_BM');

@Injectable()
export class GlueSetService extends BaseService {
  private _glusetUrl = `${this.configurations.baseApiUrl}GlueSet`;

  public isDirty: boolean;

  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService
  ) {
    super(http, conf, notificationService);
  }

  updateBeamMaterialGlueSetBatch(
    beamMaterialGlueSet: BeamMaterialGlueSet[]
  ): Observable<BeamMaterialGlueSet[]> {
    return this.http
      .put<any>(
        this._glusetUrl,
        JSON.stringify(beamMaterialGlueSet),
        this.httpOptions
      )
      .pipe(
        map((response: any[]) => this.MapGlusetData(response))
      );
  }

  updateBeamMaterialGlueSet(
    beamMaterialGlueSet: BeamMaterialGlueSet[]
  ): Observable<BeamMaterialGlueSet[]> {
    return this.http
      .put<IBeamMaterialGlueSet[]>(
        this._glusetUrl,
        JSON.stringify(beamMaterialGlueSet),
        this.httpOptions
      )
      .pipe(
        map((response: IBeamMaterialGlueSet[]) => this.MapGlusetData(response))
      );
  }

  MapGlusetData(
    response: IBeamMaterialGlueSet[]
  ): BeamMaterialGlueSet[] {
    return response.map((data) =>
      BeamMaterialGlueSetCreator.FromJson(
        data,
        this.configurations,
        false
      )
    );
  }

  getGlueSets(
    beamLaminaDimensionId: number
  ): Observable<BeamMaterialGlueSet[]> {
    const params = new HttpParams();

    if (beamLaminaDimensionId) {
      params.set('beamLaminaDimension', beamLaminaDimensionId);
    }

    return this.http
      .get(this._glusetUrl, { params })
      .pipe(
        map((response: any[]) =>
          response.map((data) =>
            BeamMaterialGlueSetCreator.FromJson(
              data,
              this.configurations,
              false
            )
          )
        )
      );
  }

  getGlueSet(
    gluesetId: number
  ): Observable<BeamMaterialGlueSet> {
    return this.http
      .get<IBeamMaterialGlueSet>(`${this._glusetUrl}/${gluesetId}`)
      .pipe(map(
        (response: IBeamMaterialGlueSet) =>

          BeamMaterialGlueSetCreator.FromJson(
            response,
            this.configurations,
            false
          )
      )
      );
  }

  getGlueSetByGluingPlanId(
    gluingPlanId: number
  ): Observable<BeamMaterialGlueSet[]> {
    return this.http
      .get<IBeamMaterialGlueSet[]>(`${this._glusetUrl}/getByPlan/${gluingPlanId}`)
      .pipe(
        map((response: IBeamMaterialGlueSet[]) =>
          this.getBeamMaterialGlueSetFromIBeamMaterialGlueSet(response)
        )
      );
  }

  getBeamMaterialGlueSetFromIBeamMaterialGlueSet(response: IBeamMaterialGlueSet[]): BeamMaterialGlueSet[] {
    return response.map((data) =>
      BeamMaterialGlueSetCreator.FromJson(
        data,
        this.configurations,
        false
      )
    );
  }

  getLamillaQuality(materialIndex: number[]): Observable<any[]> {
    let params = new HttpParams();
    materialIndex.forEach(element => {
      params = params.append('indexes', element.toString());
    });
    return this.http.get<any[]>(`${this._glusetUrl}/getLamillaQuality`, { params }).pipe(map((rep: any[]) => rep));
  }

  updateBeamMaterialGlueSetForGluingPlan(
    gluingPlanId: number,
    beamMaterialGlueSets: IBeamMaterialGlueSets
  ): Observable<IGlueplanGlusetSaveResultDTO> {
    return this.http
      .put<IGlueplanGlusetSaveResultDTO>(
        `${this._glusetUrl}/gluePlan/${gluingPlanId}`,
        beamMaterialGlueSets,
        this.httpOptions
      );
  }

  removeGlueSet(
    glusetID: number,
    guid: string,
    gluingPlanId: number
  ): Observable<IGlusetSaveResultDTO> {
    return this.http
      .delete<IGlusetSaveResultDTO>(
        `${this._glusetUrl}/${glusetID}/${guid}/${gluingPlanId}`,
        this.httpOptions
      );
  }

  getGluesetbyId(glusetID: number): Observable<BeamMaterialGlueSet> {
    return this.http
      .get<IBeamMaterialGlueSet>(`${this._glusetUrl}/getGluesetbyId/${glusetID}`)
      .pipe(
        map((response: IBeamMaterialGlueSet) =>
          BeamMaterialGlueSetCreator.FromJson(
            response,
            this.configurations,
            false
          )
        )
      );
  }
}

export interface IGlusetSaveResultDTO {
  glusetID: number;
  guiID: string;
  errorMessage: string;
  isSuccsessfull: boolean;
  glueplanNumberOfBeams: number | null;
  warnings: string[];
  glueplanTotalVolume: number | null;
}

export interface IGlueplanGlusetSaveResultDTO {
  glusetSaveResults: IGlusetSaveResultDTO[];
  glueplanNumberOfBeams: number | null;
  totalVolume: number | null;
}

export interface IBeamMaterialGlueSet {
  beamLamminaDimension: BeamLamminaDimension;
  beamLamminaDimensionID: number;
  gluesetBatchIndex: number;
  gluingPlanId: number | null;
  glueSetID: number | null;
  beamMaterialGlueSetLayers: IBeamMaterialGlueSetLayer[];
  efficiency: number;
  errorMessageText: string;
  length: number;
  locked: boolean;
  materialNeed: number;
  materialNeedM3: number;
  materialUsage: number;
  name: string;
  remove: boolean;
  save: boolean;
  send: boolean;
  Template: IBeamMaterialType;
  templateID: number | null;
  totalLamminas: number;
  performedTimes: number;
  gluingPlan: GluingPlan;
  beamMaterialGlueSetState: BeamMaterialGlueSetState;
  sequenceIndexNavigation: BeamMaterialGlueSetSequence;
  productionInstructions: string;
  guid: string;
  prodOrderId: number;
  useBlockProduction: boolean;
  plannedLamellasWithBoxInfos: IPlannedLamellasWithBoxInfo[];
  rightPlanningWidth:number;
  prePlanningWidth:number;
  chinkSaw:boolean;
  machine: IMachineDTO;
}


export interface IBeamMaterialGlueSetLayer {
  beamMaterials: IBeamMaterial[];
}

export interface IBeamMaterial {
  beamMaterialGlueSetID: number | null;
  beamMaterialID: number | null;
  beamMaterialLines: IBeamMaterialLine[];
  height: number;
  width: number;
  layer: number;
  layerOrder: number;
  locked: boolean;
  numberOfLamminas: number;
  planedThickness: number;
  productionNote: string;
  beamMaterialTypeId: number | null;
  beamMaterialType: IBeamMaterialType;
  usefullLength: number;
  planedWidth: number;
  takeTestPsc: boolean;
  testPscLenght: number;
  materialIndex: number | null;
  length: number;
  overlength: number | null;
  material: IEntity;
  isSplit: boolean;
  activityIndex: number | null;
  noOfLabels:number;
  additionalLamellaId:number;
  additionalLamellasTop:number;
  additionalLamellasBottom:number;
}

export interface IBeamMaterialLine {
  beamMaterial: IBeamMaterial;
  beamMaterialID: number | null;
  beamMaterialLineID: number | null;
  beamOrderRowID: number;
  cut: number;
  indexInCut: number;
  length: number;
  numberOfBeams: number;
  productionStateId: number | null;
  transportPackageId: number | null;
  productionStateName: string;
  productionStateCode: string;
  productionStateGroupCode: ProductionStateGroupCode;
  transportPackageDemand: IDemandDTO;
  transportPackageAllocation: ITransportPackageAllocation
}


export interface IBeamMaterialType {
  beamMaterialTypeID: number;
  color: string;
  colorLight: string;
  description: string;
  fixedHight: number;
  name: string;
  radius: number;
  typeGroupCode: number;
  isNormal: boolean;
  isSpecialForm: boolean;
  isManual: boolean;
  externalId:number;
}

export interface IBeamMaterialGlueSetState {
  glueSetStateId: number;
  name: string;
  description: string;
  isGlobal: boolean;
  isValidForGluePlan: boolean;
  canChangeToState: boolean;
  beamMaterialGlueSet: BeamMaterialGlueSet[];
}

export interface IBeamMaterialGlueSetSequence {
  sequenceIndex: number;
  entityIndex: number;
  beamMaterialGlueSetId: number;
  beamMaterialGlueSet: BeamMaterialGlueSet[];
}
