import { HttpClient, HttpParams } from '@angular/common/http';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProductionModelDTO } from '@app/core/models/production-model.model';

@Injectable()
export class ProductionModelService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService
  ) { super(http, conf, notificationService); }

  getProductionModelsForEntityId(id: number): Observable<IProductionModelDTO[]> {
    const url = `${this.configurations.baseApiUrl}ProductionModel/${id}`;
    return this.http.get<IProductionModelDTO[]>(url);
  }

  getProductionModelsForEntityIds(ids: number[] = []): Observable<IProductionModelDTO[]> {
    let params = new HttpParams();
    const distinctIds = [...new Set(ids)];

    distinctIds.forEach(i => {
      params = params.append('ids', i.toString());
    });

    return this.http.get<IProductionModelDTO[]>(`${this.configurations.baseApiUrl}ProductionModel`, { params });
  }
}
