import { HttpClient, HttpParams } from '@angular/common/http';
import { IKPIResponseDTO, IgluePlanStateResponseDTO } from '@app/core/models/analytics/plan-events';

import { IDashTileData } from '@app/modules/dashboard/models/CardItem.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GltKpiService {

  constructor(private http: HttpClient) {}

  public getNummberOfBeams(data:IDashTileData):Observable<IKPIResponseDTO>{
    return this.http.get<IKPIResponseDTO>(`${environment.apiUrl}/AnayticsGLT/kpi/numberOfBeams`, { params: this.getH(data) })
  }

  public getNummberOfPlans(data:IDashTileData):Observable<IKPIResponseDTO>{
    return this.http.get<IKPIResponseDTO>(`${environment.apiUrl}/AnayticsGLT/kpi/numberOfPlans`, { params: this.getH(data) })
  }


  public planEvents(data:IDashTileData):Observable<IgluePlanStateResponseDTO>{
    return this.http.get<IgluePlanStateResponseDTO>(`${environment.apiUrl}/AnayticsGLT/planEvents`, { params: this.getH(data) })
  }




  private getH(data:IDashTileData):HttpParams{
    var h = new HttpParams();

    h = h.append('start', data.start.toISOString());
    h = h.append('end', data.end?.toISOString());

    return h;
  }
}
