import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, of } from 'rxjs';
import { Output, EventEmitter, Directive } from '@angular/core';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { DataForCutBM, DataForCutBML } from '../../../../core/services/custom-services/copy.service';
import { AddLineParameter, Demandline } from '@app/core/models/demand-line.model';

@Directive()
export class BMLMenuOverlayRef {
  @Output() onRemoveBeamMaterial: EventEmitter<BeamMaterial> = new EventEmitter();
  @Output() onMoveUppBeamMaterial: EventEmitter<BeamMaterial> = new EventEmitter();
  @Output() onMoveDownBeamMaterial: EventEmitter<BeamMaterial> = new EventEmitter();
  @Output() onRemoveBeamMaterialLine: EventEmitter<BeamMaterialLine> = new EventEmitter();
  @Output() onAddLineToBM: EventEmitter<AddLineParameter> = new EventEmitter();
  @Output() onAddLineToBMGS: EventEmitter<AddLineParameter> = new EventEmitter();
  @Output() onPasteBM: EventEmitter<BeamMaterial> = new EventEmitter();
  @Output() onCutBeamMaterial: EventEmitter<DataForCutBM> = new EventEmitter();
  @Output() onCutBeamMaterialLine: EventEmitter<DataForCutBML> = new EventEmitter();
  @Output() onPasteCutBeamMaterial: EventEmitter<DataForCutBM> = new EventEmitter();
  @Output() onPasteCutBeamMaterialLine: EventEmitter<DataForCutBML> = new EventEmitter();
  @Output() onAdjustBMGS: EventEmitter<any> = new EventEmitter();
  @Output() onAddTestPsc: EventEmitter<BeamMaterial> = new EventEmitter();
  @Output() onRemoveTestPsc: EventEmitter<BeamMaterial> = new EventEmitter();
  @Output() onAddManualBeamMaterialToBMGS: EventEmitter<Demandline[]> = new EventEmitter();
  @Output() onUpdateBM = new EventEmitter();

  constructor(private overlayRef: OverlayRef) {}

  theBoolean: boolean;

  booleanObs(): Observable<boolean> {
    return of(this.theBoolean);
  }

  close(): void {
    this.overlayRef.dispose();
  }
}
