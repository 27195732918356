import { Injectable } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { BeamMaterial } from '../../models/beam-material.model';
import { BeamMaterialGlueSet } from '../../models/beam-material-glue-set.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CopyService {
  public cutDataBM: DataForCutBM;
  public cutDataBML: DataForCutBML;
  public activeSave: boolean = false;
  public canAddBeam$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  constructor() {
    this.cutDataBM = null;
    this.cutDataBML = null;
  }

  reset() {
    this.cutDataBM = null;
    this.cutDataBML = null;
  }

  get hasPaste():boolean {
    if (this.cutDataBM || this.cutDataBML) {
      return true;
    } else {
      return false;
    };
  }
}

export class DataForCutBM {
  constructor(
    public copiedBMs: BeamMaterial[],
    public sourceGS: BeamMaterialGlueSet
  ) {
  }
}

export class DataForCutBML {
  constructor(
    public copiedBMLs: BeamMaterialLine[],
    public sourceBM: BeamMaterial,
    public sourceGS: BeamMaterialGlueSet

  ) {
  }
}
