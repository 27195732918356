/* eslint-disable no-unused-vars */

import { Observable, of } from 'rxjs';

import { CardItem } from '../models/CardItem.model';
import { GLTkpiComponent } from '../glt-kpi/glt-kpi.component';
import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

export enum DashbordDefs {
  Generic = 0,
  Operational = 1,
  Tacktical = 2,
  UserDefined = 3,
  GLT = 4,
  CLT = 5
}

export class DashbordDef {
  public type: DashbordDefs;
  public titel: string;
  public cards: CardItem[];
}

@Injectable()
export class CardItemService {
  getCardItems(type: DashbordDefs): Observable<DashbordDef> {
    switch (type) {
      case DashbordDefs.Generic:
        return of(this.GetGenericDashbord());
      case DashbordDefs.GLT:
        return of(this.GetGltDashbord());
      default:
        throw new Error('No definition found!');
    }
  }
  GetGltDashbord(): DashbordDef {
    const c = new CardItem(GLTkpiComponent, 'Metric', 1, 1);

    c.backgroundColor = 'lightblue';
    c.showTitle = false;
    const def: DashbordDef = {

      titel: 'AppComponent.GLT_Dashbord',
      type: DashbordDefs.GLT,
      cards: [
        new CardItem(GLTkpiComponent, 'Dashboard.NumberOfBeams', 1, 1, '#43a047', false, 1, 1 ,{ kpiId : 'numberOfBeams'}),
        new CardItem(GLTkpiComponent, 'Dashboard.NumberOfPlans', 1, 1, '#43a047', false, 1, 2 ,{ kpiId : 'numberOfPlans'}),
        //new CardItem(SimpleMetricComponent, 'Metric', 1, 1, '#3f51b5', false, 2, 1),
        /*new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Production Pace', value: 'n/a', uom: 'M3/DAY' }, '#2196f3', false, 3, 1),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Avg Stop time', value: 'n/a', uom: 'H/DAY' }, '#ffc107', false, 4, 1),
        new CardItem(DiagramComponent, 'Diagram', 4, 2, {},null,false, 1, 2),
        new CardItem(PieDiagramComponent, 'Diagram', 2, 2, [
          { category: 'Eaten', value: 0.42 },
          { category: 'Not eaten', value: 0.58 }
        ],null,false, 1, 4),
        new CardItem(PieDiagramComponent, 'Red or Blue Pill', 2, 2, [
          { category: 'Red pill ', value: 0.30 },
          { category: 'Blue Pill', value: 0.40 },
          { category: 'Orange Pill', value: 0.30 }
        ],null,false, 3, 4)*/
      ]
    };

    return def;
  }

  GetGenericDashbord(): DashbordDef {
    const c = new CardItem(GLTkpiComponent, 'Metric', 1, 1);

    c.backgroundColor = 'lightblue';
    c.showTitle = false;
    const def: DashbordDef = {

      titel: 'AppComponent.General_Dashbord',
      type: DashbordDefs.Generic,
      cards: [
        new CardItem(GLTkpiComponent, 'Dashboard.NumberOfBeams', 1, 1, '#43a047', false, 1, 1 ,{ kpiId : 'numberOfBeams'}),
        new CardItem(GLTkpiComponent, 'Dashboard.NumberOfPlans', 1, 1, '#43a047', false, 1, 2 ,{ kpiId : 'numberOfPlans'}),
      ]
    };

    return def;
  }
}

marker([
  'Dashboard.NumberOfBeams',
  'Dashboard.NumberOfPlans'
])
